// Core
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
// BootStrap
import { Container, Col, Row } from "react-bootstrap";
import Logo from "../assets/images/logo/conmixinfra-logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import RoomIcon from "@mui/icons-material/Room";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const Footer = () => {
  let url = useLocation().pathname;
  const [urlHomepage, setUrlHomepage] = useState("");
  const [urlRmc, setUrlRmc] = useState("");
  const [urlBloContact, setUrlContact] = useState("");
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    navigateTo(url);
    // eslint-disable-next-line
  }, []);

  const navigateTo = (url) => {
    if (url === "/") {
      setUrlHomepage("active");
      setUrlRmc("");
      setUrlContact("");
    } else if (url === "/ready-mix-concrete") {
      setUrlHomepage("");
      setUrlRmc("active");
      setUrlContact("");
    } else if (url === "/contact-us") {
      setUrlHomepage("");
      setUrlRmc("");
      setUrlContact("active");
    }
    window.scrollTo(0, 0);
  };

  return (
    <div id="footer">
      <Container>
        <Row>
          {/* Logo and Social Media Links */}
          <Col className="col-12 col-sm-12 col-md-4 col-lg-2 text-center  mb-4 mb-md-0">
            <img
              src={Logo}
              alt="Conmix Infra Logo"
              className="img-fluid mb-3"
            />
            <div className="social">
              <ul className="list-unstyled d-flex justify-content-center">
                <li className="mx-2">
                  <Link to="#!">
                    <InstagramIcon />
                  </Link>
                </li>
                <li className="mx-2">
                  <Link to="#!">
                    <FacebookIcon />
                  </Link>
                </li>
                <li className="mx-2">
                  <Link to="#!">
                    <YouTubeIcon />
                  </Link>
                </li>
                <li className="mx-2">
                  <Link to="#!">
                    <TwitterIcon />
                  </Link>
                </li>
              </ul>
            </div>
          </Col>

          {/* Quick Links */}
          <Col className="col-12 col-sm-12 col-md-4 col-lg-2 mb-4 mb-md-0">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <h6>
                  <a href="/">Home</a>
                </h6>
              </li>
              <li>
                <h6>
                  <a href="/company">Company</a>
                </h6>
              </li>
              <li>
                <h6>
                  <a href="/ready-mix-concrete">RMC</a>
                </h6>
              </li>
              <li>
                <h6>
                  <a href="/gallery">Gallery</a>
                </h6>
              </li>
              <li>
                <h6>
                  <a href="/contact-us">Contact Us</a>
                </h6>
              </li>
            </ul>
          </Col>

          {/* Contact Us and Second Location */}
          <Col className="col-12 col-sm-12 col-md-4 col-lg-2  ">
            {/* First Location */}
            <div>
              <h5>Contact Us</h5>
              <RoomIcon />
              <p>
                Saykha Gidc, Ta. Vagra, <br />
                Dist Bharuch, <br />
                Gujarat - 392165
              </p>
              <LocalPhoneIcon />
              <p>
                <a href="tel:+91 98791 30080">+91 98791 30080</a>
              </p>
              <EmailIcon />
              <p>
                <a href="mailto:info@conmixinfra.com">info@conmixinfra.com</a>
              </p>
            </div>
          </Col>
          <Col className="col-12 col-sm-12 col-md-5 col-lg-3 mt-4 mb-4 mb-md-0 ">
            {/* Second Location */}
            <div className="">
              <RoomIcon />
              <p>
                Plant: Na-91, Survey no.217, Village-Ambeta, Dahej-Birla Copper
                road, <br />
                Ta.vagra, Dist. Bharuch, Gujarat-392 130.
              </p>
              <LocalPhoneIcon />
              <p>
                <a href="tel:+91 98791 30080">+91 98791 30080</a>
              </p>
              <EmailIcon />
              <p>
                <a href="mailto:conmixinfracon@gmail.com">
                  conmixinfracon@gmail.com
                </a>
              </p>
            </div>
          </Col>

          {/* Google Map */}
          <Col className="col-12 col-sm-12 col-md-5 col-lg-3 ">
            <h5>Google Map</h5>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3704.454562670305!2d72.8619444!3d21.8013611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe41220c40083c097!2zMjHCsDQ4JzA0LjkiTiA3MsKwNTEnNDMuMCJF!5e0!3m2!1sen!2sin!4v1663581910124!5m2!1sen!2sin"
              width="100%"
              height="150"
              style={{ border: 0 }}
              title={"Map Location"}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col md={8} className="copyright">
            {/* eslint-disable-next-line */}
            <p>
              &copy; {currentYear} Conmix Infra. All rights reserved.{" "}
              <a href="https://arkayapps.com/" target="_blank">
                Website designed and developed
              </a>{" "}
              by Arkay Apps.
            </p>
          </Col>
          <Col md={4} className="copyright links">
            <p>
              <a href="/privacy-policy">Privacy Policy</a> |{" "}
              <a href="/sitemap">Sitemap</a>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="d-block d-sm-none menu-footer">
        <Row className="p-3">
          <Col className="b-right">
            <Link
              to="/"
              className={urlHomepage}
              onClick={() => navigateTo("/")}
            >
              Home
            </Link>
          </Col>
          <Col className="b-right">
            <Link
              to="/ready-mix-concrete"
              className={urlRmc}
              onClick={() => navigateTo("/ready-mix-concrete")}
            >
              RMC
            </Link>
          </Col>
          <Col>
            <Link
              to="/contact-us"
              className={urlBloContact}
              onClick={() => navigateTo("/contact-us")}
            >
              Contact Us
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
