// Core
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
// BootStrap
import { Container, Navbar, Nav } from "react-bootstrap";
// Library
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// Media
import Logo from "../assets/images/logo/conmixinfra-logo.png";
import LogoColor from "../assets/images/logo/conmixinfra-logo-color.png";
import MenuIcon from "../assets/images/menu-icon-top.svg";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
}));

const Header = () => {
  const classes = useStyles();
  let location = useLocation().pathname;
  const [urlHomepage, setUrlHomepage] = useState("");
  const [urlCompany, setUrlCompany] = useState("");
  const [urlPlants, setUrlPlants] = useState("");
  const [urlRMC, setUrlRMC] = useState("");
  const [urlGallery, setUrlGallery] = useState("");
  const [urlContactUs, setUrlContactUs] = useState("");

  useEffect(() => {
    checkURL();
    // eslint-disable-next-line
  }, []);

  const checkURL = () => {
    if (location === "/") {
      setUrlHomepage("active");
    } else if (location === "/company") {
      setUrlCompany("active");
    } else if (location === "/plants") {
      setUrlPlants("active");
    } else if (location === "/ready-mix-concrete") {
      setUrlRMC("active");
    } else if (location === "/gallery") {
      setUrlGallery("active");
    } else if (location === "/contact-us") {
      setUrlContactUs("active");
    }
  };

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  return (
    <div className="header sticky">
      <Container>
        <Navbar expand="lg">
          {["left"].map((anchor, index) => (
            <div key={index}>
              <img
                src={MenuIcon}
                alt="Menu"
                className="d-block d-sm-none menu-icon"
                onClick={toggleDrawer(anchor, true)}
              />
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                <div
                  className={clsx(classes.list, {
                    [classes.fullList]: anchor === "top" || anchor === "bottom",
                  })}
                  role="presentation"
                  onKeyDown={toggleDrawer(anchor, false)}
                >
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader
                        component="div"
                        className="text-center"
                        id="nested-list-subheader"
                      >
                        <img src={LogoColor} alt="Conmix Infra Logo" />
                      </ListSubheader>
                    }
                    className={classes.root}
                  >
                    <Divider className="mt-4" />
                    <div>
                      <a href="/">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="Home" />
                        </ListItem>
                      </a>
                      <a href="/company">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="Company" />
                        </ListItem>
                      </a>{" "}
                      <a href="/plants">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="Plants" />
                        </ListItem>
                      </a>
                      <a href="/ready-mix-concrete">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="RMC" />
                        </ListItem>
                      </a>
                      <a href="/gallery">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="Gallery" />
                        </ListItem>
                      </a>
                      <a href="/contact-us">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="Contact Us" />
                        </ListItem>
                      </a>
                    </div>
                  </List>
                </div>
              </Drawer>
            </div>
          ))}
          <Navbar.Brand href="/">
            <img
              src={Logo}
              alt="Conmix Infra Logo"
              className="img-fluid mlogo"
            />
          </Navbar.Brand>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Link href="/" className={urlHomepage}>
                Home
              </Nav.Link>
              <Nav.Link href="/company" className={urlCompany}>
                Company
              </Nav.Link>
              <Nav.Link href="/plants" className={urlPlants}>
                Plants
              </Nav.Link>
              <Nav.Link href="/ready-mix-concrete" className={urlRMC}>
                RMC
              </Nav.Link>
              <Nav.Link href="/gallery" className={urlGallery}>
                Gallery
              </Nav.Link>
              <Nav.Link href="/contact-us" className={urlContactUs}>
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;
