import React from "react";
import RmcImg from "../assets/images/ready-mix-concrete.png";
import Design from "../assets/images/des-2.png";
import Vehicle2 from "../assets/images/vehicle-2.svg";
import { Container, Col, Row } from "react-bootstrap";
import GetInTouch from "../components/GetInTouch";
import Machinery from "../components/Machinery";
import Marquee from "react-fast-marquee";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import RoomIcon from "@mui/icons-material/Room";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

import { benefits } from "../Benefits";

const Plants = () => {
  return (
    <div>
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <h1>Plants</h1>
          <ul>
            <li>
              <a href="./">Home</a>
            </li>
            <li>Plants</li>
          </ul>
        </div>
      </section>
      <section className="entry">
        <Container>
          <Row>
            <Col md={6} className="position-relative">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3704.454562670305!2d72.8619444!3d21.8013611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe41220c40083c097!2zMjHCsDQ4JzA0LjkiTiA3MsKwNTEnNDMuMCJF!5e0!3m2!1sen!2sin!4v1663581910124!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                title={"Map Location"}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col md={6} className="my-auto">
              {/* <h2>RMC</h2> */}
              <h3>Saykha Gidc</h3>
              <RoomIcon />
              <p>
                Saykha Gidc, Ta. Vagra, Dist Bharuch, <br />
                Gujarat - 392165
              </p>
              <LocalPhoneIcon />
              <p>
                <a href="tel:+91 98791 30080">+91 98791 30080</a>
              </p>
              <EmailIcon />
              <p>
                <a href="mailto:info@conmixinfra.com">info@conmixinfra.com</a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="entry">
        <Container>
          <Row>
            <Col md={6} className="my-auto">
              {/* <h2>RMC</h2> */}
              <h3>Ambeta, Dahej-Birla Copper road</h3>
              <div className="">
                <RoomIcon />
                <p>
                  Plant: Na-91, Survey no.217, Village-Ambeta, Dahej-Birla
                  Copper road, <br />
                  Ta.vagra, Dist. Bharuch, Gujarat-392 130.
                </p>
                <LocalPhoneIcon />
                <p>
                  <a href="tel:+91 98791 30080">+91 98791 30080</a>
                </p>
                <EmailIcon />
                <p>
                  <a href="mailto:conmixinfracon@gmail.com">
                    conmixinfracon@gmail.com
                  </a>
                </p>
              </div>
            </Col>
            <Col md={6} className="position-relative">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3707.0708722256345!2d72.58109177527523!3d21.69997218012268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDQxJzU5LjkiTiA3MsKwMzUnMDEuMiJF!5e0!3m2!1sen!2sin!4v1742472518372!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                title={"Map Location"}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Plants;
